import { Stack } from "@mui/material";
import { ElpromFileMeta } from "../API/apiElpromSnippets";
import ElpromFilesStageForm from "./ElpromFilesStageForm";
import { Dispatch, SetStateAction } from "react";

interface ElpromUploadFilesModalProps {
  setIsUploadingFiles: Dispatch<SetStateAction<boolean | null>>
  filesMeta: ElpromFileMeta[];
  localStorageUploadingDateKey: string
}

const ElpromUploadFilesModal: React.FC<ElpromUploadFilesModalProps> = ({
  setIsUploadingFiles,
  filesMeta,
  localStorageUploadingDateKey
}) => {

  return (
    <Stack spacing={3} sx={{mb: 0}}>
    <ElpromFilesStageForm
        setIsUploadingFiles={setIsUploadingFiles}
        filesMeta={filesMeta}
        localStorageUploadingDateKey={localStorageUploadingDateKey}
      />
    </Stack>
  );
};

export default ElpromUploadFilesModal;
