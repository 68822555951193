import isValid from "date-fns/isValid";
import { ObjectType, SelectOption } from "../Types/commonTypes";
import format from "date-fns/format";
import parse from "date-fns/parse";
import set from "date-fns/set";

export const handleGetSelectOption = <T extends string = string>(
  stringArr: T[],
  capitalise?: boolean
): SelectOption<T>[] => {
  return stringArr.map((item) => ({
    description: capitalise ? camelCaseToTitleCase(item) : item,
    value: item,
  }));
};
export const compareStringArrayVersions = (
  newItems: string[],
  oldItems: string[]
): { added: string[]; removed: string[] } => {
  const added: string[] = newItems.filter(
    (newItem) => !oldItems.some((oldItem) => newItem === oldItem)
  );

  const removed: string[] = oldItems.filter(
    (oldItem) => !newItems.some((newItem) => newItem === oldItem)
  );

  return { added, removed };
};
/**
 * Determines the difference between the arrays, using
 * the areObjectsEqual func to compare their objects. In the result,
 * <added> contains the items present in oldItems but not in
 * oldItems. <removed> contains the items present in oldItems,
 * but not in oldItems
 */
export const compareArrayVersions = (
  newItems: ObjectType[],
  oldItems: ObjectType[]
): { added: ObjectType[]; removed: ObjectType[] } => {
  const added: ObjectType[] = newItems.filter(
    (newItem) => !oldItems.some((oldItem) => areObjectsEqual(newItem, oldItem))
  );

  const removed: ObjectType[] = oldItems.filter(
    (oldItem) => !newItems.some((newItem) => areObjectsEqual(oldItem, newItem))
  );

  return { added, removed };
};
/**
 * Checks if the 2 objects have the same key-value pairs.
 * If any of the pairs are different or the length of the
 * keys is not the same, the function returns false
 */
export const areObjectsEqual = (obj1: ObjectType, obj2: ObjectType): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Given a date as either string or Date object, this func formats the date
 * into a more readable format. It can return date/time part only or both.
 */
export const formatDateAndTime = (date: Date | string, onlyOne?: "date" | "time") => {
  // 1. format date part
  let formattedDate: Date;
  if (isValid(date)) {
    // date is in isoString format
    formattedDate = date as Date;
  } else {
    formattedDate = new Date(date);
  }
  const dateResult = format(formattedDate, "dd.MM.yyyy");

  // 2 format time part
  let formattedTime: Date;
  if (isValid(date)) {
    // time is in isoString format
    formattedTime = date as Date;
  } else {
    formattedTime = new Date(date);
  }
  const timeResult = format(formattedTime as Date, "HH:mm:ss");

  if (onlyOne === "date") {
    return dateResult;
  }
  if (onlyOne === "time") {
    return timeResult;
  }

  return `${dateResult}, ${timeResult}`;
};

/** transforms milliseconds to minutes and seconds */
export const formatMilliseconds = (milliseconds: number): string => {
  if (milliseconds < 0) {
    throw new Error("Milliseconds should be a non-negative number");
  }

  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes > 0 && seconds > 0) {
    return `${minutes} minutes and ${seconds} seconds`;
  } else if (minutes > 0) {
    return `${minutes} minutes`;
  } else {
    return `${seconds} seconds`;
  }
};

/**
 * Reverse the Date string returned from the
 * formatDateAndTime() back to a date
 */
export const transformParsedStringToDate = (dateString: any, format: string = "dd.MM.yyyy"): Date | null => {
  try {
    const transformedDate = parse(dateString, format, new Date());
    return transformedDate;
  } catch (error) {
    return null;
  }
};

/**
 * Reverse the Time string returned from the
 * formatDateAndTime() back to a time
 */
export const transformParsedStringToTime = (timeString: any): Date | null => {
  try {
    if (typeof timeString !== "string") {
      throw new Error("Invalid timeString provided");
    }

    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      throw new Error("Invalid time components");
    }

    const today = new Date();
    const transformedDate = set(today, { hours, minutes, seconds });

    return transformedDate;
  } catch (error) {
    return null;
  }
};

/** transforms a string to date and the other way around */
export const dateOrStringTransform = <T extends Date | string>(
  value: Date | string,
  getBack: "date" | "string"
): T => {
  if (isValid(value)) {
    // is of type Date
    if (getBack === "date") {
      return value as T;
    } else {
      return format(value as Date, "yyyy-MM-dd'T'HH:mm:ssxxx") as T;
    }
  }

  // is of type string
  if (getBack === "date") {
    return new Date(value) as T;
  } else {
    return value as T;
  }
};

// Set a cookie with a given name, value, and expiration date
export type SetCookieParams = {
  name: string;
  value: string;
  exp: number;
  path?: string;
  sameSite: "none" | "lax" | "strict";
  secure: boolean;
};
export const setCookie = (cookie: SetCookieParams) => {
  const { name, value, exp, path, sameSite, secure } = cookie;
  // convert from unix time to UTC string
  const expDate = new Date(exp * 1000);
  const expUtcString = expDate.toUTCString();

  const cookieSecure = secure ? " Secure;" : "";
  let cookieValue = `${name}=${encodeURIComponent(
    value
  )}; expires=${expUtcString}; path=${path || "/"};`;

  // for development on localHost
  if (!isLocalHostEnv()) {
    cookieValue += `${cookieSecure} SameSite=${sameSite};`;
  }

  document.cookie = cookieValue;
};
// Get the value of a cookie with a given name
export const getCookie = (name: string): string | null => {
  const cookieValue = document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .find((cookie) => cookie.startsWith(`${name}=`));

  if (cookieValue) {
    return cookieValue.split("=")[1];
  }

  return null;
};
// Set expiration date of the cookie to a past date
export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

// Returns true if the app is running on LocalHost
export const isLocalHostEnv = (): boolean => {
  const isLocalHost =
    typeof window !== "undefined"
      ? Boolean(
          window?.location.hostname === "localhost" ||
            // [::1] is the IPv6 localhost address.
            window?.location.hostname === "[::1]" ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window?.location.hostname.match(
              /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )
        )
      : false;

  return isLocalHost;
};

export const removePxAndReturnNumber = (input: string | number): number => {
  // If the input is already a number, return it directly
  if (typeof input === "number") {
    return input;
  }

  const numericPart = input.substring(0, input.length - 2);
  const numericValue = parseFloat(numericPart);
  return numericValue;
};

export const underscoreCaseToTitleCase = (input: string): string => {
  // Split the input string by underscores to get an array of words
  let words = input.split("_");

  // Map over each word, capitalizing the first letter and making the rest lowercase
  words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // Join the words with a space to form the final string
  return words.join(" ");
};

/**
 * Splits a camelCase string into separate words
 * and capitalizes the first word
 */
export const camelCaseToTitleCase = (input: string): string => {
  // Split the input string into words based on camelCase
  const words = input.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");

  // Capitalize the first word and join the rest of the words
  const titleCaseWords = words.map((word, index) => {
    if (index === 0) {
      // Capitalize the first word
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word.toLowerCase();
    }
  });

  // Join the words back into a single string
  return titleCaseWords.join(" ");
};

/**
 * Reverse func for camelCaseToTitleCase() func
 * Returns a string into a camelCase version
 */
export const titleCaseToCamelCase = (input: string): string => {
  const words = input.split(" ");

  // If there's only one word, return it in lowercase as is
  if (words.length === 1) {
    return words[0].toLowerCase();
  }

  // Capitalize the first word and convert the rest to camelCase
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toLowerCase() + word.slice(1);
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // Join the words together to create camelCase
  return camelCaseWords.join("");
};

export function capitalizeFirstLetterOfEachWord(input: string): string {
  return input
    .split(" ")
    .map((word) => {
      if (word.length === 0) return word;
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export const isObject = (value: any) => {
  return typeof value === "object" && value !== null && !Array.isArray(value);
};
export const isArray = (value: any) => {
  return value instanceof Array;
};

export const isTouchDevice = () => {
  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    return window.hasOwnProperty("ontouchstart") || navigator.maxTouchPoints > 0;
  }
  return false;
};

export const formatNumber = (
  numb: number | string | null,
  decimals?: boolean,
  points?: number | string | null,
  pointsIfIsDecimal?: boolean
): string | null | number => {
  if (numb === null || numb === undefined || numb === "") {
    return null;
  }

  // Convert the input to a number if it's a string
  let number: string | number = typeof numb === "string" ? parseFloat(numb) : numb;

  // Handle invalid numbers
  if (isNaN(number)) {
    return null;
  }

  // If numb is of type number and decimals is false, apply thousand separators but keep the original decimal values
  if (typeof numb === "number" && !decimals) {
    let [integerPart, decimalPart] = number.toString().split(".");
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  }

  // Check if the points logic should only apply to decimals
  if (pointsIfIsDecimal) {
    const isDecimal = number % 1 !== 0; // Check if the number has a decimal part
    if (!isDecimal) {
      // Return the number formatted without applying decimal points
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }

  // Determine the number of decimal points, limited to a maximum of 20
  let decimalPlaces = 0;
  if (typeof points === "number") {
    decimalPlaces = Math.min(points, 20);
  } else if (typeof points === "string") {
    decimalPlaces = Math.min(parseInt(points, 10), 20);
  } else if (decimals) {
    decimalPlaces = 2; // Default to 2 decimal places if decimals is true and points is not specified
  }

  // Format the number to the specified decimal places
  number = number.toFixed(decimalPlaces);

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = number.split(".");

  // Format the integer part with spaces as thousand separators
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Combine the formatted integer part with the decimal part (if it exists)
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

export const getLongestStringLength = (strings: string[]): number => {
  if (strings.length === 0) {
    return 0;
  }

  let maxLength = 0;

  for (const str of strings) {
    if (str.length > maxLength) {
      maxLength = str.length;
    }
  }

  return maxLength;
};

export const parseSecondsToHMS = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return [h, m, s].map((v) => String(v).padStart(2, "0")).join(":");
};
