import { ExcellenceWidget } from "../../../Api/Excellence/apiExcellenceSnippets";
import { BoxPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/BoxPlot/boxPlotTypes";
import { CalendarWidgetConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/CalendarWidget/calendarWidgetTypes";
import { FunnelConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Funnel/funnelTypes";
import { HeatMapConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/HeatMap/heatMapTypes";
import { NetworkConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Network/networkTypes";
import { SankeyConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Sankey/sankeyTypes";
import { ScatterPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/ScatterPlot/scatterPlotTypes";
import { SunburstConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Sunburst/sunburstTypes";
import { SwarmPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/SwarmPlot/swarmPlotTypes";
import { TimeRangeConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/TimeRange/timeRangeTypes";
import { TreeMapConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/TreeMap/treeMapTypes";
import { AreaChartConfigAndData } from "../../ExcellenceWidgets/AreaChart/areaChartTypes";
import { BarChartConfigAndData } from "../../ExcellenceWidgets/BarChart/barChartTypes";
import { ExcellenceTableConfigAndData } from "../../ExcellenceWidgets/ExcellenceTable/editExcellenceTabletypes";
import { GaugeWidgetConfigAndData } from "../../ExcellenceWidgets/GaugeWidget/gaugeWidgetUtils";
import { IndicatorWidgetConfigAndData } from "../../ExcellenceWidgets/IndicatorWidget/indicatorWidgetTypes";
import { TimeChartConfigAndData } from "../../ExcellenceWidgets/TimeChart/timeChartTypes";
import { PieChartConfigAndData } from "../../ExcellenceWidgets/PieChart/pieChartTypes";
import { RadarChartConfigAndData } from "../../ExcellenceWidgets/RadarChart/radarChartTypes";
import { TextWidgetConfigAndData } from "../../ExcellenceWidgets/TextWidget/textWidgetTypes";
import { WidgetGridItem } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { LineChartConfigAndData } from "../../ExcellenceWidgets/LineChart/lineChartTypes";
import {
  UserPrivilege,
  RolePermissionType,
  UserPermissionType,
} from "../../../context/authContextUtils";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import { GraphqlFilter } from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";

export type ExcellenceGridItemData = {
  widget: {
    chart?: ExcellenceChartOption;
  };
  loading?: boolean;
  title: string;
  id?: string | number;
};

export type ExcellenceChartOption = {
  // Excellence widgets
  lineChart?: LineChartConfigAndData;
  timeChart?: TimeChartConfigAndData;
  barChart?: BarChartConfigAndData;
  columnChart?: BarChartConfigAndData;
  table?: ExcellenceTableConfigAndData;
  pieChart?: PieChartConfigAndData;
  donutChart?: PieChartConfigAndData;
  radarChart?: RadarChartConfigAndData;
  areaChart?: AreaChartConfigAndData;
  textWidget?: TextWidgetConfigAndData;
  gaugeWidget?: GaugeWidgetConfigAndData;
  indicatorWidget?: IndicatorWidgetConfigAndData;
  simpleIndicatorWidget?: any;

  // Advanced widgets
  boxPlot?: BoxPlotConfigAndData;
  heatMap?: HeatMapConfigAndData;
  calendar?: CalendarWidgetConfigAndData;
  funnel?: FunnelConfigAndData;
  network?: NetworkConfigAndData;
  sankey?: SankeyConfigAndData;
  scatterPlot?: ScatterPlotConfigAndData;
  sunburst?: SunburstConfigAndData;
  swarmPlot?: SwarmPlotConfigAndData;
  timeRange?: TimeRangeConfigAndData;
  treeMap?: TreeMapConfigAndData;
};

export type ExcellenceUploadedFile = {
  name: string;
  url: string;
  id: string;
};

export type ExcellenceGridLayoutSettings = {
  name: string;
  id: string | number;
  existingFile?: string;
  uploadedFiles?: ExcellenceUploadedFile[];
  public: boolean;
  can_add: boolean;
  can_edit: boolean;
  can_delete: boolean;
  can_share: boolean;
};

export type ExcellenceChartKeyType = keyof ExcellenceChartOption;

export type ExcellenceWidgetItemMode = {
  itemIndex: number;
  timeChart?: {
    periodPreviewMode: boolean;
    scopeMode: boolean;
  };
};

export const excellenceHandlePrepareWidgetsData = (
  data: ExcellenceWidget[]
): WidgetGridItem<ExcellenceGridItemData>[] => {
  const result = data.map((widget) => {
    const chart = widget.content?.widget?.chart;
    const loading = Boolean(chart?.timeChart || chart?.pieChart || chart?.donutChart);

    return {
      layout: widget.content.layout,
      loading,
      id: widget.id,
      widget: widget.content.widget,
      title: widget.content.title,
    };
  });

  return result;
};

export const modalTitleTranslations = {
  "Edit layout configuration": "Edit layout configuration",
  "Confirm layout delete": "Confirm layout delete",
  "Upload File": "Upload File",
  "Share Dashboard": "Share Dashboard",
} as const;

export type ModalTitle = keyof typeof modalTitleTranslations;

export const updateExcellencePermissions = <
  T extends {
    view: boolean;
    add: boolean;
    edit: boolean;
    delete: boolean;
    share: boolean;
  }
>(
  items: T[],
  identifier: string,
  privilege: UserPrivilege,
  key: keyof T
): T[] => {
  return items.map((item) => {
    if (item[key] === identifier) {
      return { ...item, [privilege]: !item[privilege] };
    }
    return item;
  });
};

export const findExcellencePermissionChanges = (
  initialPermissions: (UserPermissionType | RolePermissionType)[],
  currentPermissions: (UserPermissionType | RolePermissionType)[],
  type: "user_id" | "role_id"
) => {
  return currentPermissions
    .filter((current) => {
      if (type === "user_id" && "user_id" in current) {
        const initial = initialPermissions.find(
          (item): item is UserPermissionType =>
            "user_id" in item && item.user_id === current.user_id
        );

        const isChanged =
          !initial ||
          initial.view !== current.view ||
          initial.add !== current.add ||
          initial.edit !== current.edit ||
          initial.delete !== current.delete ||
          initial.share !== current.share;

        return isChanged;
      } else if (type === "role_id" && "role_id" in current) {
        const initial = initialPermissions.find(
          (item): item is RolePermissionType =>
            "role_id" in item && item.role_id === current.role_id
        );
        const isChanged =
          !initial ||
          initial.view !== current.view ||
          initial.add !== current.add ||
          initial.edit !== current.edit ||
          initial.delete !== current.delete ||
          initial.share !== current.share;

        return isChanged;
      }

      return false;
    })
    .map((item) => {
      const permission_type: UserPrivilege[] = [];
      if (item.view) permission_type.push("view");
      if (item.add) permission_type.push("add");
      if (item.edit) permission_type.push("edit");
      if (item.delete) permission_type.push("delete");
      if (item.share) permission_type.push("share");

      return {
        user_id: "user_id" in item ? [item.user_id] : [],
        role_id: "role_id" in item ? [item.role_id] : [],
        permission_type,
      };
    });
};

export const formatPrivilegesMap = (
  privilegesData: { username?: string; role_name?: string; privileges: string[] }[],
  key: "username" | "role_name"
): ExcellenceUsersRolesPermissions => {
  return privilegesData.reduce((acc, item) => {
    const identifier = item[key];
    if (identifier && item.privileges) {
      acc[identifier] = item.privileges.map(
        (privilege) => privilege.toLowerCase() as UserPrivilege
      );
    }
    return acc;
  }, {} as ExcellenceUsersRolesPermissions);
};

export type ExcellenceUsersRolesPermissions = {
  [key: string]: UserPrivilege[];
};

export type ShareDashboardFormValues = {
  usersPermissions: UserPermissionType[];
  rolesPermissions: RolePermissionType[];
  isPublic: boolean;
};

export const getShareDashboardModalLabel = (
  modalData: ModalLayoutData | null
): string => {
  if (!modalData) return "";

  switch (modalData.type) {
    case "deletePermissions":
      return "Delete Permissions";
    default:
      return "";
  }
};

export const formatPermissionTypes = (permissions: string[]): UserPrivilege[] => {
  if (!permissions.includes("view")) {
    permissions.push("view");
  }
  return permissions.map(capitalizeFirstLetterOfEachWord) as UserPrivilege[];
};

export type ExcellenceItemFilter = {
  currentFilters: GraphqlFilter[];
  itemIndex: number;
  isConfigured: boolean;
};
