import { BarColumnRadarData } from "../EditExcellenceChartForms/excellenceChartFormUtils";

export const MAX_DATA_BARS = 1000;
const MAX_DATA_MINI_BARS = 20;
export const CHART_COMBINED_KEY = "Other (Combined)";
export type BarDownSampledCombinedData = {
  group: DatumValue | undefined;
  data: {
    label: string;
    value: DatumValue;
  }[];
}[];

interface BarDownSampledData {
  dynamicData: BarColumnRadarData;
  notRenderedData: BarColumnRadarData;
  combinedSegments: BarDownSampledCombinedData;
}
export const getBarDownSampledData = (
  data: BarColumnRadarData,
  indexBy?: string
): BarDownSampledData => {
  const reducedData = data.slice(0, MAX_DATA_BARS);
  const dynamicData: BarColumnRadarData = [];
  const notRenderedData: BarColumnRadarData =
    data.length > MAX_DATA_BARS ? data.slice(MAX_DATA_BARS, data.length) : [];

  reducedData.forEach((mainBar) => {
    const miniBarArr = Object.entries(mainBar);
    const reducedMiniBarsArr = miniBarArr.slice(0, MAX_DATA_BARS);
    const reducedMiniBarsObj = Object.fromEntries(reducedMiniBarsArr);

    dynamicData.push(reducedMiniBarsObj);
  });

  const { barData, combinedSegments } = limitBarSegments(
    dynamicData,
    MAX_DATA_MINI_BARS,
    indexBy
  );
  return { dynamicData: barData, notRenderedData, combinedSegments };
};

type DatumValue = string | number;
interface ParsedBarData {
  barData: BarColumnRadarData;
  combinedSegments: BarDownSampledCombinedData;
}

const limitBarSegments = (
  data: BarColumnRadarData,
  maxSegments: number,
  indexBy = "group"
): ParsedBarData => {
  const parsedData = data.map((item) => {
    const keys = Object.keys(item);

    const groupKey = indexBy;
    const groupValue = item[groupKey];
    const otherKeys = keys.filter((key) => key !== groupKey);

    const sortedKeys = otherKeys.sort((a, b) => parseFloat(a) - parseFloat(b));

    if (sortedKeys.length <= maxSegments) {
      return { barData: item, combinedSegments: {} };
    }

    const limitedItem: { [key: string]: DatumValue } = { [groupKey]: groupValue };
    const combinedSegments: { [key: string]: DatumValue } = {};
    let otherSegmentTotal = 0;

    // Add the first maxSegments segments
    sortedKeys.slice(0, maxSegments).forEach((key) => {
      limitedItem[key] = item[key];
    });

    // Combine the remaining segments into one and track the combined data
    sortedKeys.slice(maxSegments).forEach((key) => {
      const value = item[key];
      combinedSegments[key] = value; // Track the combined key-value pairs

      if (typeof value === "number") {
        otherSegmentTotal += value;
      } else if (!isNaN(Number(value))) {
        otherSegmentTotal += Number(value);
      }
    });

    limitedItem[CHART_COMBINED_KEY] = otherSegmentTotal.toString();

    return {
      barData: limitedItem,
      combinedSegments: {
        data: combinedSegments,
        group: groupValue,
      },
    };
  });

  const barData = parsedData.map((p) => p.barData);
  const combinedSegments = parsedData.map((p) => p.combinedSegments);

  const finalCombinedSegmentsData = combinedSegments
    .filter((seg) => !!seg.data?.length && Object.keys(seg.data)?.[0])
    .map((item) => ({
      group: item.group,
      data: Object.keys(item.data!).map((key) => ({
        label: key,
        value: item.data![key],
      })),
    }));

  return { barData, combinedSegments: finalCombinedSegmentsData };
};
