import GuestLayout from "./Layout/GuestLayout";
import { useAuthedContext } from "./context/AuthContext";
import { Box } from "@mui/material";
import AppRouter from "./Layout/Router/AppRouter";
import LoadingScreen from "./Components/SmallComponents/LoadingScreen/LoadingScreen";

const App: React.FC = () => {
  const { authedUser, authedUserLoading } = useAuthedContext();

  if (authedUserLoading) {
    return <LoadingScreen />;
  }

  if (authedUser) {
    return (
      <Box component="main">
        <AppRouter />
      </Box>
    );
  }

  return <GuestLayout />;
};

export default App;
