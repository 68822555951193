import { Box, IconButton, Menu } from "@mui/material";
import {
  DynamicGridChartOptions,
  DynamicGridItemData,
} from "../../Components/SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import { GetQueryExcellenceFullDashboardSnippet } from "../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceFullDashboard } from "../../Api/Excellence/apiExcellenceGetQueries";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import { AppRouterProps } from "../../Layout/layoutVariables";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { WidgetGridItem } from "../../Components/LargeComponents/WidgetsGrid/widgetsGridUtils";
import {
  ExcellenceGridItemData,
  ExcellenceGridLayoutSettings,
  ExcellenceItemFilter,
  ExcellenceWidgetItemMode,
  excellenceHandlePrepareWidgetsData,
} from "../../Components/PageComponents/Excellence/excellenceUtils";
import WidgetsGridLayout from "../../Components/LargeComponents/WidgetsGrid/WidgetsGridLayout";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import cssComponentsStyles from "../../Global/Styles/components";
import ExcellenceChartItem from "../../Components/PageComponents/Excellence/ExcellenceChartItem";
import ExcellenceItemRightMenu from "../../Components/PageComponents/Excellence/ExcellenceItemRightMenu";
import ExcellenceDashboardExtraPageMenu from "../../Components/PageComponents/Excellence/ExcellenceDashboardExtraPageMenu";
import ExcellenceWidgetModes from "../../Components/PageComponents/Excellence/ExcellenceWidgetModes";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceParameters } from "../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceParameter } from "../../GraphQL/Excellence/graphQLTypesExcellence";
import { ErrorBoundary } from "react-error-boundary";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import ExcellenceWidgetTempFilters from "../../Components/PageComponents/Excellence/ExcellenceWidgetTempFilters";

type SelectedItem = {
  item: WidgetGridItem<ExcellenceGridItemData>;
  index: number;
  width: number;
  height: number;
} | null;

const ExcellenceDashboard: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const location = useLocation();
  const [settings, setSettings] = useState<ExcellenceGridLayoutSettings | null>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null); // initial loading
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [initialGridItems, setInitialGridItems] = useState<DynamicGridItemData[]>([]);

  const [widgetItems, setWidgetItems] = useState<
    WidgetGridItem<ExcellenceGridItemData>[]
  >([]);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(null);
  const [itemModes, setItemModes] = useState<ExcellenceWidgetItemMode[]>([]);
  const [updatedWidget, setUpdatedWidget] = useState<string | null>(null);
  const [parameters, setParameters] = useState<ExcellenceParameter[]>([]);
  const [timeParameters, setTimeParameters] = useState<ExcellenceParameter[]>([]);
  const [itemFilters, setItemFilters] = useState<ExcellenceItemFilter[]>([]);
  const [refetchOnFilters, setRefetchOnFilters] = useState<boolean>(false);

  const widgetRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const { smMediaQuery, setExtraRightNavMenu, setExtraTopNavMenu, setUnsavedChanges } =
    useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();

  const openMenu = Boolean(menuAnchor);
  const [getParamsData] = useLazyQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );
  const [getTimeParamsData] = useLazyQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage("Loading...");

        fetchDashboard();

        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect on mount err ", err);
        setPageStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    // Check for changes in gridItems and set unsavedChanges to true if it's changed
    const items = widgetItems.map((item) => removeDataKey(item));
    const initialItems = initialGridItems.map((item) => removeDataKey(item));
    const areEqual = JSON.stringify(items) === JSON.stringify(initialItems);
    setUnsavedChanges(!areEqual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetItems, initialGridItems]);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      if (settings) {
        setExtraTopNavMenu(<PageTitle title={settings.name} />);
        setExtraRightNavMenu(
          <ExcellenceDashboardExtraPageMenu
            setWidgetItems={setWidgetItems}
            widgetItems={widgetItems}
            settings={settings}
            setUnsavedChanges={setUnsavedChanges}
            setSettings={setSettings}
            handleFetchParams={handleFetchParams}
            setInitialGridItems={setInitialGridItems}
            dashboardRef={dashboardRef}
            location={location.pathname}
          />
        );
      }
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery, widgetItems, settings]);

  const fetchDashboard = async () => {
    if (!params.id) {
      throw new Error("ID from params not found for this excellence dashboard");
    }

    const data = await callApi<GetQueryExcellenceFullDashboardSnippet>({
      query: getQueryExcellenceFullDashboard(params.id),
      auth: { setAuthedUser },
    });

    await handleFetchParams();

    setSettings({
      id: data.id,
      name: data.name,
      existingFile: data.file_name,
      public: data.public,
      can_add: data.can_add,
      can_edit: data.can_edit,
      can_delete: data.can_delete,
      can_share: data.can_share,
    });

    const items = excellenceHandlePrepareWidgetsData(data.widgets);
    setWidgetItems(items);
    setInitialGridItems(items);
  };

  const handleFetchParams = async () => {
    try {
      const paramsResult = await getParamsData({
        variables: {
          dashboardId: params.id,
        },
      });
      const timeParamsResult = await getTimeParamsData({
        variables: {
          dashboardId: params.id,
          timeseries: true,
        },
      });

      if (!paramsResult.data || paramsResult.error) {
        throw new Error(JSON.stringify(paramsResult));
      }

      setParameters(paramsResult.data.parameters);
      if (timeParamsResult.data?.parameters) {
        setTimeParameters(timeParamsResult.data.parameters);
      }
    } catch (err) {
      console.log("handleFetchParams err ", err);
    }
  };

  const handleUpdateWidgetsLayout = useCallback((data: WidgetGridItem<any>[]) => {
    setWidgetItems(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenItemMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: WidgetGridItem<any>,
    index: number,
    width: number,
    height: number
  ) => {
    setMenuAnchor(event.currentTarget);
    setSelectedItem({ item, index, width, height });
  };
  const handleCloseItemMenu = () => {
    setMenuAnchor(null);
    setSelectedItem(null);
  };

  const handleUnlockWidget = (widgetIndex: number) => {
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            isLocked: !item?.isLocked,
          };
        }
        return item;
      });
    });
  };

  return (
    <Box component="div">
      {smMediaQuery && settings ? (
        <ExcellenceDashboardExtraPageMenu
          setWidgetItems={setWidgetItems}
          widgetItems={widgetItems}
          settings={settings}
          setUnsavedChanges={setUnsavedChanges}
          setSettings={setSettings}
          handleFetchParams={handleFetchParams}
          setInitialGridItems={setInitialGridItems}
          dashboardRef={dashboardRef}
          location={location.pathname}
        />
      ) : null}

      <Box component="div" ref={dashboardRef}>
        {/* TODO: below alert isn't shown at the moment */}
        <Alert
          css={[styles.widthLimit25]}
          message={alertMessage}
          showAlert={false}
          severity={pageStatus}
        />

        {smMediaQuery ? (
          settings?.name ? (
            <PageTitle css={styles.labelBreak} title={settings.name} />
          ) : null
        ) : null}

        {settings ? (
          <>
            <WidgetsGridLayout
              dataItems={widgetItems}
              updateDataItems={handleUpdateWidgetsLayout}
              rowHeight={150}
              canMove={settings.can_add || settings.can_edit}
              renderRightMenu={(item, index, width, height) => (
                <>
                  <ExcellenceWidgetModes
                    widgetItem={item as WidgetGridItem<ExcellenceGridItemData>}
                    widgetIndex={index}
                    widgetModes={itemModes}
                    setWidgetModes={setItemModes}
                  />
                  {/* To DO: Delete this component and his connections (itemFilters and setRefetchOnFilters) */}
                  {false && (
                    <ExcellenceWidgetTempFilters
                      widgetItem={item as WidgetGridItem<ExcellenceGridItemData>}
                      widgetIndex={index}
                      itemFilters={itemFilters}
                      setItemFilters={setItemFilters}
                      parameters={parameters}
                      timeParameters={timeParameters}
                      setRefetchOnFilters={setRefetchOnFilters}
                    />
                  )}

                  {item.isLocked ? (
                    <IconButton
                      aria-label="unlock item"
                      onClick={() => handleUnlockWidget(index)}
                    >
                      <LockOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="widget setting menu"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleOpenItemMenu(e, item, index, width, height)
                      }
                    >
                      <TuneOutlinedIcon css={styles.greyIcon} />
                    </IconButton>
                  )}
                </>
              )}
              render={(item, index, isUpdatingWidget) => (
                <ErrorBoundary
                  FallbackComponent={() => (
                    <Box component="div">
                      <Alert severity="error" message="Something went wrong" showAlert />
                    </Box>
                  )}
                >
                  <Box
                    ref={index === selectedItem?.index ? widgetRef : null}
                    style={
                      isUpdatingWidget
                        ? { display: "none", width: "100%", height: "100%" }
                        : {
                            width: "100%",
                            height: "100%",
                          }
                    }
                    component="div"
                  >
                    <ExcellenceChartItem
                      chart={item.widget.chart}
                      title={item.title}
                      widgetLoading={item.loading}
                      widgetModes={itemModes}
                      widgetIndex={index}
                      isUpdated={updatedWidget === item?.layout?.lg?.i}
                      setUpdatedWidgetToNull={() => setUpdatedWidget(null)}
                      parameters={parameters}
                      timeParameters={timeParameters}
                      widgetID={item.layout.lg.i || ""}
                      tempFilters={
                        itemFilters.find((fil) => fil.itemIndex === index)
                          ?.currentFilters || []
                      }
                      refetchOnFilters={refetchOnFilters}
                    />
                  </Box>
                </ErrorBoundary>
              )}
            />

            <Menu
              anchorEl={menuAnchor}
              open={openMenu}
              onClose={handleCloseItemMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {selectedItem ? (
                <ExcellenceItemRightMenu
                  widgetItem={selectedItem.item}
                  widgetIndex={selectedItem.index}
                  setWidgetItems={setWidgetItems}
                  settings={settings}
                  handleCloseMenu={handleCloseItemMenu}
                  widID={selectedItem?.item?.layout?.lg?.i || ""}
                  handleUpdateWidget={(wid) => setUpdatedWidget(wid)}
                  parameters={parameters}
                  timeParameters={timeParameters}
                  chartRef={widgetRef}
                />
              ) : null}
            </Menu>
          </>
        ) : null}
      </Box>
 
    </Box>
  );
};

export default ExcellenceDashboard;

function removeDataKey(obj: DynamicGridItemData): DynamicGridItemData {
  const result = structuredClone(obj);
  delete result.loading;
  for (const key in result.widget.chart) {
    if (Object.prototype.hasOwnProperty.call(result.widget.chart, key)) {
      // @ts-ignore
      delete result.widget.chart[key as keyof DynamicGridChartOptions]
        ?.data as unknown as DynamicGridItemData;
    }
  }
  return result;
}
