import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import {
  WidgetGridItem,
  WidgetGridItemLayout,
} from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import {
  ExcellenceChartKeyType,
  ExcellenceChartOption,
  ExcellenceGridItemData,
  ExcellenceGridLayoutSettings
} from "./excellenceUtils";
import { v4 as uuidv4 } from "uuid";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { RefObject, useState } from "react";
import Modal from "../../MaterialUI/Modal";
import Button from "../../MaterialUI/Button";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import EditDynamicChartForm from "../../ExcellenceWidgets/EditExcellenceChartForms/EditDynamicChartForm";
import ExcellenceSwitchOptions from "./ExcellenceSwitchOptions";
import { handleSwitchChartType } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useTranslatedModalTitle } from "../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../context/LanguageContext";
import ExportAsImage from "../../SmallComponents/ExportAs/ExportAsImage";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const modalTitleTranslations = {
  "Edit layout configuration": "Edit layout configuration",
  "Confirm Delete": "Confirm Delete",
  "Edit configuration": "Edit configuration",
} as const;

type ModalTitle = keyof typeof modalTitleTranslations;

interface ExcellenceItemRightMenuProps {
  widgetItem: WidgetGridItem<ExcellenceGridItemData>;
  widgetIndex: number;
  setWidgetItems: React.Dispatch<
    React.SetStateAction<WidgetGridItem<ExcellenceGridItemData>[]>
  >;
  settings: ExcellenceGridLayoutSettings | null;
  handleCloseMenu: () => void;
  widID: string;
  handleUpdateWidget: (widID: string) => void;
  parameters: ExcellenceParameter[];
  timeParameters: ExcellenceParameter[];
  chartRef: RefObject<HTMLDivElement>;
}

const ExcellenceItemRightMenu: React.FC<ExcellenceItemRightMenuProps> = ({
  widgetItem,
  widgetIndex,
  setWidgetItems,
  settings,
  handleCloseMenu,
  widID,
  handleUpdateWidget,
  parameters,
  timeParameters,
  chartRef,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const { t } = useLanguageContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [switchMenuAnchor, setSwitchMenuAnchor] = useState<null | HTMLElement>(null);
  const openSwitchMenu = Boolean(switchMenuAnchor);
  const chartType = getExcellenceChartType(widgetItem.widget.chart);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const disabledSwitch =
    !settings?.can_edit ||
    chartType === "gaugeWidget" ||
    chartType === "textWidget" ||
    chartType === "indicatorWidget";
  const handleEdit = () => {
    setModalTitle("Edit configuration");
  };

  const handleCopy = () => {
    const newId = uuidv4().split("-")[0];
    const position = {
      x: 0,
      y: Infinity,
    };

    const updatedLayout: WidgetGridItemLayout = Object.keys(widgetItem.layout).reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: {
            ...widgetItem.layout[curr as keyof WidgetGridItemLayout],
            i: newId,
            ...position,
          },
        };
      },
      {} as WidgetGridItemLayout
    );

    const newItem: WidgetGridItem<ExcellenceGridItemData> = {
      ...widgetItem,
      ...(widgetItem?.id && { id: uuidv4() }),
      title: `${widgetItem.title} - Copy`,
      layout: updatedLayout,
    };
    setWidgetItems((prev) => [newItem, ...prev]);
    handleUpdateWidget(widID);
    handleCloseMenu();
  };

  const handleRemoveWidget = () => {
    setModalTitle("Confirm Delete");
  };

  const handleConfirmRemove = () => {
    setWidgetItems((prev) => {
      const arr = [...prev];
      arr.splice(widgetIndex, 1);
      return arr;
    });
    setModalTitle(null);
    handleCloseMenu();
  };

  const handleLockWidget = () => {
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            isLocked: !item?.isLocked,
          };
        }
        return item;
      });
    });
    handleCloseMenu();
  };

  const handleOpenSwitchMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSwitchMenuAnchor(event.currentTarget);
  };

  const handleEditWidgetSubmitForm = (
    updatedChart: ExcellenceChartOption | null,
    title: string
  ) => {
    if (widgetIndex === null) {
      throw new Error("Invalid item index");
    }
    if (!updatedChart) {
      throw new Error("Invalid updatedChart");
    }
    setWidgetItems((prev) => {
      return prev.map((item, index) => {
        if (index === widgetIndex) {
          return {
            ...item,
            widget: {
              ...item.widget,
              chart: {
                ...updatedChart,
              },
            },
            title: title,
            loading: !item.loading,
          };
        }
        return item;
      });
    });
    handleUpdateWidget(widID);
    setModalTitle(null);
    setUnsavedChanges(false);
    handleCloseMenu();
  };

  const handleSwitch = (newType: ExcellenceChartKeyType) => {
    setWidgetItems((prev) => {
      const copiedItems = structuredClone(prev);
      const updatedItem = structuredClone(prev[widgetIndex]);

      const currentType = getExcellenceChartType(updatedItem.widget.chart);
      if (newType && currentType) {
        const updatedChart = handleSwitchChartType(
          currentType,
          newType,
          updatedItem.widget.chart
        );

        if (updatedChart) {
          updatedItem.widget.chart = updatedChart;
        }
      }

      const result = structuredClone(copiedItems);

      result[widgetIndex] = updatedItem;
      return result;
    });

    setSwitchMenuAnchor(null);
    handleUpdateWidget(widID);
    handleCloseMenu();
  };

  const handleCloseModal = () => {
    handleCloseMenu();
    setModalTitle(null);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
        <Stack alignItems="center">
          <IconButton
            aria-label="edit widget configuration"
            onClick={handleEdit}
            disabled={!settings?.can_edit}
          >
            <EditOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Edit")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label="copy widget"
            onClick={handleCopy}
            disabled={!settings?.can_edit}
          >
            <DifferenceOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Copy")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label="switch widget"
            onClick={handleOpenSwitchMenu}
            disabled={disabledSwitch}
          >
            <PublishedWithChangesOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            color={disabledSwitch ? "textSecondary" : "textPrimary"}
          >
            {t("Switch")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label={widgetItem.layout.xl.isResizable ? "lock" : "unlock"}
            onClick={handleLockWidget}
            disabled={!settings?.can_edit}
          >
            <LockOpenOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {!widgetItem.layout.xl.isResizable ? t("Unlock") : t("Lock")}
          </Typography>
        </Stack>
        <Stack alignItems="center">
          <IconButton
            aria-label="delete"
            onClick={handleRemoveWidget}
            disabled={!settings?.can_delete}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center">
            {t("Delete")}
          </Typography>
        </Stack>
        <ExportAsImage
          isPrint
          elementRef={chartRef}
          fileName={widgetItem.title}
          iconButton={
            <Stack style={{ zIndex: 5 }} alignItems="center">
              <IconButton aria-label="open export menu">
                <PrintOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                Print
              </Typography>
            </Stack>
          }
        />
        <ExportAsImage
          elementRef={chartRef}
          fileName={widgetItem.title}
          iconButton={
            <Stack style={{ zIndex: 5 }} alignItems="center">
              <IconButton aria-label="open export menu">
                <FileDownloadOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center">
                Export
              </Typography>
            </Stack>
          }
        />
      </Stack>

      <Menu
        anchorEl={switchMenuAnchor}
        open={openSwitchMenu}
        onClose={() => setSwitchMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box component="div" css={[styles.leftRightPadding1]}>
          <Stack direction="row" spacing={1}>
            <ExcellenceSwitchOptions
              chart={chartType}
              handleSwitch={handleSwitch}
              tableDataFormat={widgetItem.widget.chart?.table?.config.dataFormat}
            />
          </Stack>
        </Box>
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={handleCloseModal}
        fullWidth
        maxWidth={modalTitle === "Edit configuration" ? "lg" : "sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalTitle === "Confirm Delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete the widget")}
            </Typography>
            <Button onClick={handleConfirmRemove}>{t("Confirm Delete")}</Button>
          </Stack>
        ) : null}

        {modalTitle === "Edit configuration" ? (
          <EditDynamicChartForm
            chart={widgetItem.widget.chart}
            widgetTitle={widgetItem.title}
            handleSaveChanges={handleEditWidgetSubmitForm}
            parameters={{ parameters: parameters }}
            timeParameters={{ parameters: timeParameters }}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            widgetID={widgetItem.layout.lg.i}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ExcellenceItemRightMenu;

const getExcellenceChartType = (
  chart: ExcellenceChartOption | undefined
): ExcellenceChartKeyType | null => {
  if (!chart) {
    return null;
  }

  const entries = Object.entries(chart);
  const found = entries.find((item) => Boolean(item[1]));
  if (found) {
    return found[0] as ExcellenceChartKeyType;
  }

  return null;
};
