import { TableGridColumnSchema } from "../../../Components/SmallComponents/TableGrid/constructTableGrid";

export type ElpromCurrency = {
  code: string;
  name: string;
  rate: number;
  auto_update: boolean;
  id: string;
  last_updated: string;
};

export type ElpromFileTypes = "prices" | "supco" | "kso" | "priorities";
export type ElpromFileMeta = {
  file_name: string;
  file_type: ElpromFileTypes;
  file_url: string;
  id: string;
  uploaded_at: string;
};

export type ElpromTableData = {
  rows: Record<string, any>[];
  columns: TableGridColumnSchema[];
};

export type ElpromTableDataDiscrepancies = {
  rows: Record<string, any>[];
  columns: TableGridColumnSchema[];
  newcoming_material_codes: string[];
};

export type ElpromUploadStatusData = {
  finished: boolean;
  status: string
}

export const TAB_NAMES = ["collateral_summary","supco", "kso", "prices", "prices_po" , "collateral_not_distributed", "collateral_compare"]
export type TabName = typeof TAB_NAMES[number];
export type UserAccessKeys = TabName | "upload_files";
export type ElpromUserAccessPermisionData = {
  [key in UserAccessKeys]: boolean;
} & {
  role_id: string;
};

export type ElpromProjectCalendarData = {
  client_order: string;
  start_date: string;
  end_date: string;
  working_station: string;
}[]

export type KanbanColumn = {
  id: string;
  title: string;
  order: number;
}

export type KanbanTask = {
  id: string;
  column_id: string;
  title: string;
  description: string;
  order: number;
  status: string;
}

export type ElpromProjectKanbanData = {
  tasks: KanbanTask[];
  columns: KanbanColumn[];
}

