import {
  MRT_Cell,
  MRT_ColumnDef,
  MRT_FilterFn,
  MRT_SortingFn,
  MRT_SortingFns,
} from "material-react-table";
import {
  TableGridColAlign,
  TableGridColSymbol,
  TableGridColumnDataTypes,
  TableGridHandleSaveCellChanges,
} from "./tableGridUtils";
import {
  renderCellValue,
  TableGridCellComponent,
  TableGridEditComponent,
} from "./TableGridColumnComponents";
import {
  formatDateAndTime,
  formatNumber,
  underscoreCaseToTitleCase,
} from "../../../Global/Utils/commonFunctions";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";

export enum TableAggregationFns {
  sum = "sum",
  min = "min",
  max = "max",
  mean = "mean",
  median = "median",
  uniqueCount = "uniqueCount",
  count = "count",
}
export type TableAggregationKey = keyof typeof TableAggregationFns;
export type TableFooterData = Record<TableAggregationKey, number>;
export type TableDynamicCondition = {
  operation: "less" | "more" | "equal" | "always";
  cellValue: number;
};
type ConditionalBgColor = {
  condition: string;
  color: string;
}
export type TableCellsConfig = {
  id?: string;
  backgroundConfig?: {
    mode: "static" | "dynamic" | "compare";
    backgroundColor: string | ConditionalBgColor[] | ConditionalBgColor;
    compareColumn: string;
    filling: "partial" | "full";
    minValue?: number;
    maxValue?: number;
  };
};

type StringPair = Record<string, any>;
export type TableGridColumnSchema = {
  id: string;
  label: string;
  type: TableGridColumnDataTypes;
  width?: number;
  marked?: boolean;
  markedId?: string;
  columns?: TableGridColumnSchema[];
  disableEditing?: boolean;
  bolded100?: boolean;
  formatNumb?: boolean;
  formatNumbDecimals?: number;
  aggregationFn?: Array<TableAggregationKey>;
  footerAggregations?: TableFooterData;
  colBgColor?: string;
  dynamicBgColor?: TableDynamicCondition & {
    //
    bgColor: string;
    partialFillBasedOnVal?: boolean;
  };
  alignCol?: TableGridColAlign;
  symbol?: TableGridColSymbol;
  cellsConfig?: TableCellsConfig;
};
type FilterVariant =
  | "text"
  | "checkbox"
  | "multi-select"
  | "range"
  | "range-slider"
  | "select"
  | undefined;

const constructTableGridColumns = <T extends StringPair>(
  schema: TableGridColumnSchema[],
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T>[] => {  
  return schema.map((col) => {
    if (col.columns?.length) {
      
      return {
        id: col.id,
        header: col.label,
        columns: constructTableGridColumns(col.columns, handleSaveCellChanges),
        size: col.width,
      };
    }
    
    return getColumnCellData(col, handleSaveCellChanges);
  });
};

export default constructTableGridColumns;

const getColumnCellData = <T extends StringPair>(
  col: TableGridColumnSchema,
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T> => {
  let columnFilterModeOptions = undefined;
  let accessorFn: ((originalRow: StringPair) => any) | undefined = undefined;
  let sortingFn: MRT_SortingFn<StringPair> | undefined = undefined;
  let enableGlobalFilter: boolean | undefined = undefined;
  let enableSorting: boolean | undefined = undefined;
  let filterVariant: FilterVariant = undefined;
  let aggregationFn = col.aggregationFn as unknown as string[] | undefined;
  let filterFn: MRT_FilterFn<StringPair> = "contains";

  const createSortingFnAggregationSupport = (type: keyof typeof MRT_SortingFns | MRT_SortingFn<StringPair>) => {
    const fn: MRT_ColumnDef<Record<string, any>, unknown>["sortingFn"] = (rowA, rowB, columnId) => {
      const isGroupedA = rowA.getIsGrouped();
      const isGroupedB = rowB.getIsGrouped();

      const rowValueA = isGroupedA ? rowA.getGroupingValue(columnId) : rowA.getValue(columnId);
      const rowValueB = isGroupedB ? rowB.getGroupingValue(columnId) : rowB.getValue(columnId);

      const valueToCompareA = Array.isArray(rowValueA) ? rowValueA[0] : (rowValueA ?? 0)
      const valueToCompareB = Array.isArray(rowValueB) ? rowValueB[0] : (rowValueB ?? 0)

      if (typeof type === "function") {
        return type(
          { ...rowA, getValue: () => valueToCompareA},
          { ...rowB, getValue: () => valueToCompareB},
          columnId,
        );
      }

      return MRT_SortingFns[type as keyof typeof MRT_SortingFns](
        { ...rowA, getValue: () => valueToCompareA},
        { ...rowB, getValue: () => valueToCompareB},
        columnId,
      );
    };
  
    return fn;
  };

  const customAlphanumeric = (rowA: StringPair, rowB: StringPair, columnId: string) => {
    const valueA = rowA.getValue(columnId) === "" ? -Infinity : Number(rowA.getValue(columnId)) || 0;
    const valueB = rowB.getValue(columnId) === "" ? -Infinity : Number(rowB.getValue(columnId)) || 0;
    return valueA - valueB;
  };

  switch (col.type) {
    case "string": {
      columnFilterModeOptions = [
        "contains",
        "fuzzy",
        "startsWith",
        "endsWith",
        "empty",
        "notEmpty",
      ];
      sortingFn = "alphanumeric";
      break;
    }
    case "number": {
      sortingFn = createSortingFnAggregationSupport(customAlphanumeric);
      break;
    }
    case "boolean": {
      enableGlobalFilter = false;
      filterVariant = "checkbox";
      enableSorting = false;
      break;
    }
    case "date": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "date") : "");
      sortingFn = "customDateTime";
      break;
    }
    case "time": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "time") : "");
      sortingFn = "customDateTime";
      break;
    }
    case "datetime": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id]) : "");
      sortingFn = "customDateTime";
      break;
    }
    case "button": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
    case "dropdown": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
  }

  const result: MRT_ColumnDef<Record<string, any>, unknown> = {
    accessorKey: col.id,
    header: col.label,
    Header: ({ column }) => (
      <Tooltip title={column.columnDef.header}>
        <Typography
          variant="body2"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {column.columnDef.header}
        </Typography>
      </Tooltip>
    ),
    meta: {
      type: col.type,
    },
    size: col.width,
    columnFilterModeOptions,
    aggregationFn: aggregationFn as any,
    AggregatedCell: aggregationFn?.length
      ? ({ cell }) => (
          <Stack spacing={1} direction="row">
            {aggregationFn?.map((item, index) => (
              <Typography variant="body2">
                {underscoreCaseToTitleCase(item)}:{" "}
                <Typography fontWeight="bold" variant="body2" component="span">
                  {renderCellValue(
                    formatNumber(
                      cell.getValue<Array<number>>()?.[index],
                      true,
                      2,
                      true
                    ) ?? "",
                    col.symbol
                  )}
                </Typography>
              </Typography>
            ))}
          </Stack>
        )
      : undefined,
    Cell: ({ cell, row }) => (
      <TableGridCellComponent
        cellValue={cell.getValue<string>()}
        type={col.type}
        marked={col.marked}
        markedId={col.markedId}
        rowData={row.original}
        bolded100={col.bolded100}
        formatNumb={col.formatNumb}
        formatNumbDecimals={col.formatNumbDecimals}
        alignCol={col.alignCol}
        symbol={col.symbol}
      />
    ),
    Edit: ({ cell }) => (
      <TableGridEditComponent
        rowIndex={cell.row.index}
        colKey={cell.column.id}
        colValue={cell.getValue()}
        type={col.type}
        handleSaveCellChanges={handleSaveCellChanges}
        disableEditing={col.disableEditing}
      />
    ),
    Footer:
      aggregationFn?.length && col.footerAggregations
        ? () => (
            <Stack
              spacing={1}
              direction="row"
              sx={{
                backgroundColor: col.colBgColor,
                width: "100%",
                height: "100%",
              }}
            >
              {aggregationFn?.map((item) => (
                <Typography variant="body2">
                  {underscoreCaseToTitleCase(item)}:{" "}
                  <Typography fontWeight="bold" variant="body2" component="span">
                    {renderCellValue(
                      col.footerAggregations![item as TableAggregationKey] ?? "",
                      col.symbol
                    )}
                  </Typography>
                </Typography>
              ))}
            </Stack>
          )
        : undefined,
    accessorFn,
    sortingFn,
    filterFn,
    filterVariant,
    enableGlobalFilter,
    enableSorting,
    muiTableBodyCellProps: ({ cell }) => {
      return {
        sx: {
          background: getCellBgColor(col, cell),
        },
      };
    },
  };

  return result as MRT_ColumnDef<T>;
};

// AGGREGATION FUNCTIONS ------------------------

// Function to calculate median
const calculateMedian = (numbers: number[]): number => {
  const sorted = [...numbers].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
};

// The main function
export const calcTableFooterAggregations = (
  aggregationFns: TableAggregationKey[],
  numbers: number[],
  decimalPoints?: number
): TableFooterData => {
  const footer: TableFooterData = {} as TableFooterData;

  aggregationFns.forEach((fn) => {
    switch (fn) {
      case TableAggregationFns.sum:
        const sum = numbers.reduce((acc, curr) => acc + curr, 0);
        footer[fn] = formatNumber(sum, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.min:
        const min = Math.min(...numbers);
        footer[fn] = formatNumber(min, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.max:
        const max = Math.max(...numbers);
        footer[fn] = formatNumber(max, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.mean:
        const mean = numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;
        footer[fn] = formatNumber(mean, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.median:
        const median = calculateMedian(numbers);
        footer[fn] = formatNumber(median, true, decimalPoints || 2, true) as number;
        break;
      case TableAggregationFns.uniqueCount:
        footer[fn] = [...new Set(numbers)].length;
        break;
      case TableAggregationFns.count:
        footer[fn] = numbers.length;
        break;
    }
  });

  return footer;
};

const compare = (operation: string | undefined, value: number, comparisonValue: number): boolean => {
  if (!operation) {
    return false;
  }

  switch (operation) {
    case "<":
      return value < comparisonValue;
    case "<=":
      return value <= comparisonValue;
    case ">":
      return value > comparisonValue;
    case ">=":
      return value >= comparisonValue;
    case "!=":
      return value !== comparisonValue;
    case "=":
    case "==":
      return value === comparisonValue;
    default:
      return false;
  }
};

const getDynamicCondition = (condition: string, cellVal: any): boolean => {
  const conditions = condition.split("&&").map(cond => cond.trim());
  let result = true;

  conditions.forEach(cond => {
    const operationMatch = cond.match(/^[<>=]+/);
    const operation = operationMatch ? operationMatch[0] : undefined;
    const conditionValue = operation ? parseFloat(cond.slice(operation.length)) : NaN;
    
    if (isNaN(conditionValue)) {
      result = false;
      return;
    }

    result = result && compare(operation, cellVal, conditionValue);
  });

  return result;
};

const compareValue = (value: number, comparisonString: string): boolean => {
  const operationMatch = comparisonString.match(/^[!<>=]+/);
  
  if (!operationMatch) {
    return false; 
  }
  
  const operation = operationMatch[0];
  const rightSideExpression = comparisonString.slice(operation.length).trim();
  
  let comparisonValue: number;
  
  const hasMathOperators = /[\+\-\*\/\%]/.test(rightSideExpression);
  
  if (hasMathOperators) {
    try {
      comparisonValue = new Function('return ' + rightSideExpression)();
    } catch (error) {
      console.error("Error evaluating expression:", rightSideExpression, error);
      return false;
    }
  } else {
    comparisonValue = parseFloat(rightSideExpression);
  }
  
  if (isNaN(comparisonValue)) {
    return false;
  }

  return compare(operation, value, comparisonValue);
};

const isNumericValue = (value: any): boolean => {
  const val = Array.isArray(value) ? value[0] : value;

  if (typeof val === 'number') return true;
  if (typeof val === 'string') {
    const num = Number(val);
    return !isNaN(num) && isFinite(num);
  }
  return false;
};

const getPartialFillGradient = (
  color: string,
  cellVal: number,
  minValue: number = 0,
  maxValue: number = 100
): string => {
  const percentage = Math.min(100, Math.max(0, ((cellVal - minValue) / (maxValue - minValue)) * 100));
  return `linear-gradient(to right, ${color} ${percentage}%, transparent ${percentage}%)`;
};

const evaluateComparisonExpression = (value: number, comparisonExpression: string): boolean => {
  const conditions = comparisonExpression.split("&&").map(cond => cond.trim());
  
  return conditions.every(condition => compareValue(value, condition));
}; 

const handleStaticMode = (bgConfig: any, cellVal: any): string | undefined => {
  if (bgConfig.filling === "partial" && isNumericValue(cellVal)) {
    const color = getPartialFillGradient(
      bgConfig.backgroundColor as string,
      Number(cellVal),
      bgConfig.minValue,
      bgConfig.maxValue
    );
    return color;
  }
  return bgConfig.backgroundColor as string;
};

const handleDynamicMode = (bgConfig: any, cellVal: any): string | undefined => {
  if (Array.isArray(bgConfig.backgroundColor)) {
    const backgroundColorConditions = bgConfig.backgroundColor;

    for (let i = 0; i < backgroundColorConditions.length; i++) {
      const item = backgroundColorConditions[i];
      if (typeof item === "object" && "condition" in item && "color" in item) {
        const { condition, color } = item;
        const numericCellVal = isNumericValue(cellVal) ? Number(cellVal) : cellVal;
        if (getDynamicCondition(condition, numericCellVal)) {
          if (bgConfig.filling === "partial" && isNumericValue(cellVal)) {
            return getPartialFillGradient(
              color,
              Number(cellVal),
              bgConfig.minValue,
              bgConfig.maxValue
            );
          }
          return color;
        }
      }
    }
  }
  return undefined;
};

const handleCompareMode = (bgConfig: any, cell: MRT_Cell<Record<string, any>, unknown>, cellVal: any): string | undefined => {
  if (isNumericValue(cellVal)) {
    const compareColumnId = bgConfig.compareColumn;
    if (!compareColumnId) {
      return undefined;
    }

    const row = cell.row.original;
    const compareValue = row[compareColumnId];

    if (!isNumericValue(compareValue)) {
      return undefined;
    }

    if (Array.isArray(bgConfig.backgroundColor)) {
      const backgroundColorConditions = bgConfig.backgroundColor;

      for (let i = 0; i < backgroundColorConditions.length; i++) {
        const item = backgroundColorConditions[i];
        if (typeof item === "object" && "condition" in item && "color" in item) {
          const { condition, color } = item;
          const compareCondition = condition.replace("{value}", compareValue);
          if (evaluateComparisonExpression(Number(cellVal), compareCondition)) {
            return color;
          }
        }
      }
    }
  }
  return undefined;
};

const getCellBgColor = (
  col: TableGridColumnSchema,
  cell: MRT_Cell<Record<string, any>, unknown>
): string | undefined => {
  const theme = useTheme();
  const cellVal = cell.getValue();

  if (col.id === "quantity" && isNumericValue(cellVal)) {
    const numericCellVal = Number(cellVal);
    const row = cell.row.original as Record<string, any>;
    const { min_amount, max_amount } = row;

    if (numericCellVal < min_amount || numericCellVal > max_amount) {
      return theme.palette.error.main;
    }

    const minThreshold = min_amount + 0.1 * min_amount;
    const maxThreshold = max_amount - 0.1 * max_amount;

    if (numericCellVal < minThreshold || numericCellVal > maxThreshold) {
      return theme.palette.warning.main;
    }

    return undefined;
  }

  const bgConfig = col.cellsConfig?.backgroundConfig;

  if (!bgConfig) {
    return undefined;
  }

  switch (bgConfig.mode) {
    case "static":
      return handleStaticMode(bgConfig, cellVal);
    case "dynamic":
      return handleDynamicMode(bgConfig, cellVal);
    case "compare":
      return handleCompareMode(bgConfig, cell, cellVal);
    default:
      return undefined;
  }
};

