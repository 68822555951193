import { useLocation, useParams, useNavigate } from "react-router-dom";
import ElpromClientOrderPage from "./ElpromClientOrderPage";
import ProjectHubDataPage from "./ProjectHubDataPage";
import ROUTES_MAPPING from "../../Layout/Router/routesMapping";
import ElpromCompareClientOrder from "./ElpromCompareClientOrder";
import { useEffect } from "react";

/*This component is used to force React to keep ProjectHubDataPage mounted while the ElpromClientOrderPage is open, 
  so going back to the ProjectHubDataPage doesn't cause a component reload */

const ProjectHubWrapper = () => {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (id && !getComponentToRender()) {
            navigate('/GIANT-Planning/project-hub-data');
        }
    }, [location.pathname, id]);
    
    const getComponentToRender = () => {
        if(id) {
            const basePathSplit = location.pathname.split("/");
            basePathSplit.pop();
            const basePath = basePathSplit.join("/")
            const path = `${basePath}/:id`
    
            switch (path) {
                case ROUTES_MAPPING["projectHubClientOrder"]: {
                    return <ElpromClientOrderPage />
                }  
                case ROUTES_MAPPING["projectHubCompareCollateral"]: {
                    return <ElpromCompareClientOrder />
                }
                default: {
                    return null;
                }    
            }
        }
        return null;
    }
  
    return (
      <>
        <ProjectHubDataPage visible={!id}/>
        {getComponentToRender()}
      </>
    );
  };

  export default ProjectHubWrapper;