import { Query } from "../../../Api/callApi";
import {
  PostQueryElpromCurrenciesInput,
  PostQueryElpromUploadSupcoInput,
  PostQueryElpromUploadAllInput
} from "./apiElpromInputs";

export const postQueryElpromCurrencies = (
  input: PostQueryElpromCurrenciesInput
): Query => ({
  endpoint: "elprom_collateral/currencies",
  method: "PUT",
  variables: input,
});

export const postQueryElpromUploadAll = (
  input: PostQueryElpromUploadAllInput
): Query => ({
  endpoint: 'elprom_collateral/upload-all',
  method: "POST",
  variables: input,
  multipartForm: true,
})

export const postQueryElpromUploadSupco = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `elprom_collateral/upload-supco`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadKso = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `elprom_collateral/upload-kso`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadPrices = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `elprom_collateral/upload-prices`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryElpromUploadPriorities = (
  input: PostQueryElpromUploadSupcoInput
): Query => ({
  endpoint: `elprom_collateral/upload-priorities`,
  method: "POST",
  variables: input,
  multipartForm: true,
});
