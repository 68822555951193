import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuthedContext } from '../../context/AuthContext';
import callApi from '../../Api/callApi';
import ResponsiveTableGrid from '../../Components/SmallComponents/TableGrid/ResponsiveTableGrid';
import { getQueryElpromProjectMonitoringData } from './API/apiElpromGetQueries';
import DatePicker from '../../Components/MaterialUI/DateTimePickers/DatePicker';
import { format } from 'date-fns';
import { useLanguageContext } from '../../context/LanguageContext';

const VendorDeficitsPage: React.FC = () => {
  const { setAuthedUser } = useAuthedContext();
  const [tableData, setTableData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { t } = useLanguageContext()
  
  const [fromDate, setFromDate] = useState<Date>(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  });
  const [toDate, setToDate] = useState<Date>(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await callApi<any[]>({
          query: getQueryElpromProjectMonitoringData(
            format(fromDate, 'yyyy-MM-dd'),
            format(toDate, 'yyyy-MM-dd')
          ),
          auth: { setAuthedUser },
        });
        setTableData(data);
      } catch (error) {
        console.error('Error fetching project monitoring data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fromDate, toDate, setAuthedUser]);

  return (
    <Box component="div">
      <Typography variant="h2" textAlign="center" mb={2}>
        {t("Vendor Deficits")}
      </Typography>
      <Box component="div" sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
        <Box component="div">
          <Typography variant="body2" sx={{ mb: 1 }}>From</Typography>
          <DatePicker
            value={fromDate}
            onChange={(newValue) => newValue && setFromDate(newValue)}
            maxDate={toDate}
            disabled={loading}
          />
        </Box>
        <Box component="div">
          <Typography variant="body2" sx={{ mb: 1 }}>To</Typography>
          <DatePicker
            value={toDate}
            onChange={(newValue) => newValue && setToDate(newValue)}
            minDate={fromDate}
            disabled={loading}
          />
        </Box>
      </Box>
        <ResponsiveTableGrid
          rows={tableData?.rows || []}
          colSchema={tableData?.columns || []}
          responsive="responsive"
          configuration={tableData?.configuration || {}}
          tableID="ProjectMonitoringPage_table"
          loading={loading}
        />
    </Box>
  );
};

export default VendorDeficitsPage;
