import { Stack, IconButton, Typography, Theme, Menu, useTheme } from "@mui/material";
import { WidgetGridItem } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import {
  ExcellenceChartOption,
  ExcellenceGridItemData,
  ExcellenceGridLayoutSettings,
  excellenceHandlePrepareWidgetsData,
  ModalTitle,
  modalTitleTranslations,
} from "./excellenceUtils";
import { css } from "@emotion/react";
import { RefObject, useState } from "react";
import { useNavigate } from "react-router-dom";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../context/AuthContext";
import Modal from "../../MaterialUI/Modal";
import Button from "../../MaterialUI/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import PanToolAltOutlinedIcon from "@mui/icons-material/PanToolAltOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  DeleteQueryExcellenceDashboard,
  PostQueryExcellenceWidgetsInput,
} from "../../../Api/Excellence/apiExcellenceInputs";
import {
  deleteQueryExcellenceDashboard,
  postQueryExcellenceWidgets,
} from "../../../Api/Excellence/apiExcellencePostQueries";
import {
  ExcellenceWidget,
  ExcellenceWidgetFirstItem,
  PostQueryExcellenceWidgetsSnippets,
} from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { Layout } from "react-grid-layout";
import { v4 as uuidv4 } from "uuid";
import AddNewExcellenceChart from "./AddNewExcellenceChart";
import UpdateExcellenceDashboard from "./ExcellenceModals/UpdateExcellenceDashboard";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExcellenceUploadFileForm from "./ExcellenceModals/ExcellenceUploadFileForm";
import { useTranslatedModalTitle } from "../../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../../context/LanguageContext";
import ShareIcon from "@mui/icons-material/Share";
import { DynamicGridItemData } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import ShareDashboardForm from "./ShareDashboardForm";
import ExportAsImage from "../../SmallComponents/ExportAs/ExportAsImage";
import CustomIconsTooltip from "../../SmallComponents/Tooltip/CustomIconsTooltip";

const cssStyles = (theme: Theme) => ({
  addMenu: css({
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }),
});

interface ExcellenceDashboardExtraPageMenuProps {
  setWidgetItems: React.Dispatch<
    React.SetStateAction<WidgetGridItem<ExcellenceGridItemData>[]>
  >;
  widgetItems: WidgetGridItem<ExcellenceGridItemData>[];
  settings: ExcellenceGridLayoutSettings;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  setSettings: React.Dispatch<React.SetStateAction<ExcellenceGridLayoutSettings | null>>;
  handleFetchParams: () => Promise<void>;
  setInitialGridItems: React.Dispatch<React.SetStateAction<DynamicGridItemData[]>>;
  dashboardRef: RefObject<HTMLDivElement>;
  location: string;
}

const ExcellenceDashboardExtraPageMenu: React.FC<
  ExcellenceDashboardExtraPageMenuProps
> = ({
  setWidgetItems,
  widgetItems,
  settings,
  setUnsavedChanges,
  setSettings,
  handleFetchParams,
  setInitialGridItems,
  dashboardRef,
  location,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();
  const getTranslatedModalTitle = useTranslatedModalTitle(modalTitleTranslations);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [modalTitle, setModalTitle] = useState<ModalTitle | null>(null);
  const [addNewMenuAnchor, setAddNewMenuAnchor] = useState<null | HTMLElement>(null);
  const [fileExists, setFileExists] = useState<boolean>(!!settings.existingFile);
  const [unsavedEditChanges, setUnsavedEditChanges] = useState<boolean>(false);
  const [doOpenUnsavedChangesModal, setDoOpenUnsavedChangesModal] =
    useState<boolean>(false);

  const openAddNewMenu = Boolean(addNewMenuAnchor);
  const navigate = useNavigate();
  const isExcellence = location.includes("Excellence");

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedEditChanges(true);
    }
  };

  const handleOpenAddNewMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddNewMenuAnchor(event.currentTarget);
  };
  const handleCloseAddNewMenu = () => {
    setAddNewMenuAnchor(null);
  };

  type WidgetDeminsion = {
    w: number;
    h: number;
  };

  const getWidgetDeminsion = (chart: ExcellenceChartOption): WidgetDeminsion => {
    const isIndicator = chart.indicatorWidget;
    // const isKpi = chart.kpiWidget;
    if (isIndicator) {
      return {
        w: 3,
        h: 2
      };
    }
    // if (isKpi) {
    //   return {
    //     w: 6,
    //     h: 2,
    //   };
    // }

    return {
      w: 6,
      h: 3,
    };
  };

  const handleAddNewWidget = (chart: ExcellenceChartOption, title: string) => {
    const widgetDeminsion = getWidgetDeminsion(chart);
    const newItemLayout = {
      i: uuidv4().split("-")[0],
      x: 0,
      y: 0,
      w: widgetDeminsion.w,
      h: widgetDeminsion.h,
      isResizable: true,
      isBounded: false,
    };
    const newWidget: WidgetGridItem<ExcellenceGridItemData> = {
      layout: {
        xl: newItemLayout,
        lg: newItemLayout,
        md: newItemLayout,
        sm: newItemLayout,
        xs: newItemLayout,
      },
      widget: {
        chart: {
          ...chart,
        },
      },
      title,
    };

    // IMPORTANT!!! Keep the newly added item
    // as the first element of the array. Look at logic for
    // handleOnLayoutChange() in the <WidgetsGridLayout/> component
    setWidgetItems((prev) => [newWidget, ...prev]);
    handleCloseAddNewMenu();
  };

  const handleSaveWidgets = async () => {
    try {
      const firstItems = handleGetFirstWidget(widgetItems);

      const charts: ExcellenceWidget[] = widgetItems.map((item, index) => {
        const { id, widget, ...other } = item;
        let formattedContent = { ...other, widget };
        const firstItemBreakpoint: ExcellenceWidgetFirstItem = [];
        if (firstItems.firstDesktopItemIndex === index) {
          firstItemBreakpoint.push("desktop");
        }
        if (firstItems.firstMobileItemIndex === index) {
          firstItemBreakpoint.push("mobile");
        }

        if (widget.chart?.timeChart) {
          const { data, ...otherData } = widget.chart.timeChart;
          formattedContent = {
            ...formattedContent,
            widget: {
              ...formattedContent.widget,
              chart: {
                ...formattedContent.widget.chart,
                timeChart: {
                  ...otherData,
                  data: null,
                },
              },
            },
          };
        }
        if (widget.chart?.pieChart) {
          const { data, ...otherData } = widget.chart.pieChart;
          formattedContent = {
            ...formattedContent,
            widget: {
              ...formattedContent.widget,
              chart: {
                ...formattedContent.widget.chart,
                pieChart: {
                  ...otherData,
                  data: null,
                },
              },
            },
          };
        }
        if (widget.chart?.donutChart) {
          const { data, ...otherData } = widget.chart.donutChart;
          formattedContent = {
            ...formattedContent,
            widget: {
              ...formattedContent.widget,
              chart: {
                ...formattedContent.widget.chart,
                donutChart: {
                  ...otherData,
                  data: null,
                },
              },
            },
          };
        }

        return {
          content: formattedContent,
          firstItemBreakpoint: firstItemBreakpoint.length ? firstItemBreakpoint : null,
          ...(id && { id: id }),
        };
      });

      const saveChangesInput: PostQueryExcellenceWidgetsInput = {
        layoutID: settings.id,
        widgets: charts,
      };

      const result = await callApi<PostQueryExcellenceWidgetsSnippets>({
        query: postQueryExcellenceWidgets(saveChangesInput),
        auth: { setAuthedUser },
      });

      const preparedData = excellenceHandlePrepareWidgetsData(result.widgets);
      setWidgetItems(preparedData);
      setInitialGridItems(preparedData);
      setUnsavedChanges(false);
    } catch (err) {
      console.log("err ", err);
    }
  };

  const handleDeleteLayout = async () => {
    try {
      setFormStatus("loading");
      const redirectURL = isExcellence
        ? "/GIANT-Analytics/Excellence/"
        : "/GIANT-Analytics/Advanced-Analytics/";

      const input: DeleteQueryExcellenceDashboard = settings.id;

      await callApi<string>({
        query: deleteQueryExcellenceDashboard(input),
        auth: { setAuthedUser },
      });

      setFormStatus(null);
      setUnsavedChanges(false);
      navigate({
        pathname: redirectURL,
      });
    } catch (err) {
      console.log("handleDeleteLayout() func ", err);
      setFormStatus("error");
    }
  };

  return (
    <>
      <Stack spacing={1} justifyContent="flex-end" direction="column">
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Add")}>
            <IconButton
              aria-label="add new widget"
              onClick={handleOpenAddNewMenu}
              disabled={!settings.can_add}
            >
              <AddOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Edit")}>
            <IconButton
              aria-label="edit layout settings"
              onClick={() => setModalTitle("Edit layout configuration")}
              disabled={!settings.can_edit}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Upload")}>
            <IconButton
              aria-label="upload file"
              onClick={() => setModalTitle("Upload File")}
              disabled={!settings.can_edit}
            >
              <FileUploadIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Duplicate")}>
            <IconButton aria-label="duplicate dashboard" disabled>
              <FileCopyIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>

        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Select")}>
            <IconButton aria-label="select custom variant" disabled>
              <PanToolAltOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Delete")}>
            <IconButton
              aria-label="delete layout"
              onClick={() => setModalTitle("Confirm layout delete")}
              disabled={!settings.can_delete}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Save")}>
            <IconButton
              aria-label="save changes"
              onClick={handleSaveWidgets}
              disabled={!settings.can_edit}
            >
              <SaveOutlinedIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
        <ExportAsImage fileName={settings.name} elementRef={dashboardRef} isPrint />
        <ExportAsImage fileName={settings.name} elementRef={dashboardRef} />
        <Stack alignItems="center">
          <CustomIconsTooltip tooltipText={t("Share")}>
            <IconButton
              aria-label="save changes"
              disabled={!settings.can_share}
              onClick={() => setModalTitle("Share Dashboard")}
            >
              <ShareIcon />
            </IconButton>
          </CustomIconsTooltip>
        </Stack>
      </Stack>

      <Menu
        anchorEl={addNewMenuAnchor}
        open={openAddNewMenu}
        onClose={handleCloseAddNewMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <AddNewExcellenceChart
          css={[styles.addMenu, styles.labelBreak]}
          handleAddNewChart={(chart, title) => handleAddNewWidget(chart, title)}
          isExcellence={isExcellence}
        />
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={modalTitle === "Share Dashboard" ? "md" : "sm"}
        label={modalTitle ? getTranslatedModalTitle(modalTitle) : ""}
        unsavedChanges={unsavedEditChanges}
        setUnsavedChanges={setUnsavedEditChanges}
        doOpenUnsavedChangesModal={doOpenUnsavedChangesModal}
        setDoOpenUnsavedChangesModal={setDoOpenUnsavedChangesModal}
      >
        {modalTitle === "Confirm layout delete" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>
              {t("Clicking the below button will delete this dashboard")}
            </Typography>
            <Button onClick={handleDeleteLayout} loading={formStatus === "loading"}>
              {t("Confirm Delete")}
            </Button>
          </Stack>
        ) : null}

        {modalTitle === "Edit layout configuration" ? (
          <UpdateExcellenceDashboard
            settings={settings}
            handleCloseModal={() => setModalTitle(null)}
            setSettings={setSettings}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {modalTitle === "Upload File" ? (
          <ExcellenceUploadFileForm
            dashboardID={`${settings.id}`}
            handleCloseModal={() => setModalTitle(null)}
            fileExists={fileExists}
            setFileExists={setFileExists}
            handleFetchParams={handleFetchParams}
          />
        ) : null}
        {modalTitle === "Share Dashboard" ? (
          <ShareDashboardForm
            id={`${settings.id}`}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            isInitiallyPublic={settings.public}
            unsavedEditChanges={unsavedEditChanges}
            setUnsavedEditChanges={setUnsavedEditChanges}
            setDoOpenUnsavedChangesModal={setDoOpenUnsavedChangesModal}
            setModalTitle={setModalTitle}
            setSettings={setSettings}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ExcellenceDashboardExtraPageMenu;

const handleGetFirstWidget = (items: WidgetGridItem<ExcellenceGridItemData>[]) => {
  const desktopLayout: Layout[] = [];
  const mobileLayout: Layout[] = [];
  for (const item of items) {
    desktopLayout.push(item.layout.xl);
    mobileLayout.push(item.layout.xs);
  }

  // Sort the items based on X coordinate first and then by Y coordinate
  desktopLayout.sort((a, b) => {
    if (a.x === b.x) {
      return a.y - b.y;
    }
    return a.x - b.x;
  });
  mobileLayout.sort((a, b) => {
    if (a.x === b.x) {
      return a.y - b.y;
    }
    return a.x - b.x;
  });

  // The leftmost item on the top row
  const firstDesktopItemIndex = items.findIndex(
    (item) => item.layout.xl.i === desktopLayout[0].i
  );
  const firstMobileItemIndex = items.findIndex(
    (item) => item.layout.xs.i === mobileLayout[0].i
  );

  return {
    firstDesktopItemIndex,
    firstMobileItemIndex,
  };
};
