import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { memo, useEffect, useState } from "react";
import { getCurrentPageItems } from "../../../MaterialUI/Pagination/paginationUtils";
import {
  Box,
  Divider,
  Stack,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  Grid,
} from "@mui/material";
import Pagination from "../../../MaterialUI/Pagination/Pagination";
import ContentBox from "../../../MaterialUI/ContentBox";
import cssFontStyles from "../../../../Global/Styles/font";
import {
  TableGridHandleSaveCellChanges,
  TableGridPropsEditMode,
} from "../tableGridUtils";
import {
  TableGridCellComponent,
  TableGridEditComponent,
} from "../TableGridColumnComponents";
import { css } from "@emotion/react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SearchIcon from "@mui/icons-material/Search";
import MobileTableGridFilters from "./MobileTableGridFilters";
import Collapse from "../../../MaterialUI/Collapse";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  MobileTableGridFormattedRow,
  MobileTableGridProps,
} from "./mobileTableGridUtils";
import { MRT_ColumnDef } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";

const cssStyles = (theme: Theme) => ({
  evenRow: css({
    background:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.customColors.darkGrey,
  }),
});

const ITEMS_PER_PAGE = 10;

const MobileTableGrid = <T extends Record<string, any>>({
  className,
  mobileRows,
  columns,
  editMode,
  editedRowIndex,
  configuration,
  loading,
  globalEditing,
  internalEditMode,
  toggleEditMode,
  handleSaveEditChanges,
  onSave,
  tableState
}: MobileTableGridProps<T>) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssFontStyles,
  };
  const [filteredMobileRows, setFilteredMobileRows] =
    useState<MobileTableGridFormattedRow[]>(mobileRows);
  const [pageRows, setPageRows] = useState<MobileTableGridFormattedRow[]>(
    getCurrentPageItems(mobileRows, 1, ITEMS_PER_PAGE)
  );
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  /**
   * UseEffect logic number 1:
   * When mobileRows changes update the filtered rows value
   */
  useEffect(() => {
    if (mobileRows.length && !filteredMobileRows.length) {
      setFilteredMobileRows(mobileRows);
    } else {
      setFilteredMobileRows((prev) => {
        return prev.map((row) => {
          const key = Object.keys(row);
          const rowIndex = row?.[key?.[0]]?.rowIndex;
          if (rowIndex === editedRowIndex) {
            return mobileRows[editedRowIndex];
          }

          return row;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileRows, editedRowIndex]);

  /**
   * UseEffect logic number 2:
   * When filteredMobileRows changes update the page rows value
   */
  useEffect(() => {
    setPageRows(() => getCurrentPageItems(filteredMobileRows, pageIndex, ITEMS_PER_PAGE));
  }, [filteredMobileRows, pageIndex]);

  return (
    <Box component="div" className={className}>
      <ContentBox css={[styles.sectionBreak, styles.topBottomPadding2]} noGutters>
        <Box component="div" css={[styles.leftRightPadding2, styles.sectionBreak]}>
          <Grid spacing={3} container direction={{ xs: "column-reverse", sm: "row" }}>
            <Grid item xs={12} sm={7}>
              <Collapse in={showSearch}>
                <MobileTableGridFilters
                  showSearch={showSearch}
                  formattedRows={mobileRows}
                  setFilteredRows={setFilteredMobileRows}
                  setPageIndex={setPageIndex}
                />
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Stack
                spacing={1}
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
              >
                <>
                  {globalEditing && (
                    <Stack alignItems="center">
                      {!internalEditMode ? (
                        <>
                          <Tooltip title="Toggle edit mode">
                            <IconButton onClick={toggleEditMode}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Typography variant="caption">Edit</Typography>
                        </>
                      ) : (
                        <>
                          <Tooltip title="Save changes">
                            <IconButton onClick={() => {
                              if (toggleEditMode) {
                                toggleEditMode();
                              }
                              if (handleSaveEditChanges) {  
                                handleSaveEditChanges();
                              }
                              if (onSave && tableState) {
                                onSave(tableState);
                              }
                            }}>
                              <SaveAsIcon />
                            </IconButton>
                          </Tooltip>
                          <Typography variant="caption">Save</Typography>
                        </>
                      )}
                    </Stack>
                  )}
                  {(internalEditMode && editMode) ? (
                    <>
                      <Stack alignItems="center">
                        <Tooltip title="Add new record">
                          <IconButton onClick={editMode?.handleAddNewRow}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="caption">Add</Typography>
                      </Stack>
                      <Divider flexItem orientation="vertical" />
                    </>
                  ) : null}

                  {!configuration?.hideMobileSearch ? (
                    <Stack alignItems="center">
                      <Tooltip title="Show/Hide search">
                        <IconButton onClick={() => setShowSearch((prev) => !prev)}>
                          {showSearch ? <SearchOffIcon /> : <SearchIcon />}
                        </IconButton>
                      </Tooltip>
                      <Typography variant="caption">Search</Typography>
                    </Stack>
                  ) : null}
                </>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        {pageRows.length ? (
          <Stack css={styles.sectionBreak} spacing={6}>
            {pageRows.map((row, rowIndex) => (
              <Box
                component="div"
                css={[rowIndex % 2 !== 0 && styles.evenRow, styles.leftRightPadding2]}
                key={`mobile-list-row-${rowIndex}`}
              >
                <Stack css={styles.reverseTextBreak} spacing={2}>
                  {/* Row Block */}

                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="caption">
                      Row: {rowIndex + 1 + ITEMS_PER_PAGE * (pageIndex - 1)}/
                      {filteredMobileRows.length}
                    </Typography>
                    {internalEditMode && editMode ? (
                      <Tooltip title="Delete record">
                        <IconButton onClick={() => editMode.handleDeleteRow(rowIndex)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Stack>

                  {columns.map((col, colIndex) => (
                    <Stack
                      spacing={1}
                      key={`mobile-list-row-${rowIndex}-col-${colIndex}`}
                    >
                      <ColumnCellsList
                        column={col as MRT_ColumnDef<any>}
                        parentColIndex={colIndex}
                        rowIndex={rowIndex}
                        editMode={internalEditMode ? editMode : undefined}
                        row={row}
                      />
                    </Stack>
                  ))}
                </Stack>
                {rowIndex + 1 !== pageRows.length ? (
                  <Divider css={styles.reverseSectionBreak} />
                ) : null}
              </Box>
            ))}
          </Stack>
        ) : (
          <Typography
            css={[styles.italicText, styles.sectionBreak]}
            variant="body1"
            align="center"
            color="textSecondary"
          >
            {loading ? "Fetching data..." : "No records to display"}
          </Typography>
        )}

        {!configuration?.hideMobilePagination ? (
          <Box component="div" css={[styles.leftRightPadding2, styles.flexCenter]}>
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE}
              items={filteredMobileRows}
              pageIndex={pageIndex}
              setPageItems={setPageRows}
              setPageIndex={setPageIndex}
            />
          </Box>
        ) : null}
      </ContentBox>
    </Box>
  );
};

export default memo(MobileTableGrid);

interface ColumnCellsListProps {
  column: MRT_ColumnDef<any>;
  parentColIndex: number;
  rowIndex: number;
  row: MobileTableGridFormattedRow;
  editMode?: TableGridPropsEditMode;
  internalEditMode?: boolean;
}

const ColumnCellsList: React.FC<ColumnCellsListProps> = ({
  column,
  parentColIndex,
  rowIndex,
  row,
  editMode,
}) => {
  if (column.columns?.length) {
    return (
      <Box component="div">
        <Typography variant="h4" color="primary">
          {column.header}
        </Typography>
        {column.columns?.map((col, colIndex) => (
          <ColumnCell
            key={`mobile-list-row-${rowIndex}-parent-col-${parentColIndex}-column-${colIndex}`}
            col={col}
            row={row}
            editMode={editMode}
          />
        ))}
      </Box>
    );
  }

  return <ColumnCell col={column} row={row} editMode={editMode} />;
};

interface ColumnCellProps {
  col: MRT_ColumnDef<any>;
  row: MobileTableGridFormattedRow;
  editMode?: TableGridPropsEditMode;
}

const ColumnCell: React.FC<ColumnCellProps> = ({ col, row, editMode }) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssFontStyles };

  return (
    <Stack
      css={styles.leftRightPadding2}
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      {/* CELL Block */}

      {editMode ? null : (
        <Typography css={styles.bolderText} variant="body1">
          {col.header}
        </Typography>
      )}
      <MobileTableGridCell
        row={row}
        colKey={(col?.accessorKey as string) || ""}
        marked={col?.accessorKey === "jobSatisfaction"}
        editMode={
          editMode
            ? {
                handleSaveCellChanges: editMode.handleSaveCellChanges,
                fieldLabel: col.header,
              }
            : undefined
        }
      />
    </Stack>
  );
};

interface MobileTableGridCellProps {
  row: MobileTableGridFormattedRow;
  colKey: string;
  marked?: boolean;
  editMode?: {
    handleSaveCellChanges: TableGridHandleSaveCellChanges;
    fieldLabel: string;
  };
}

const MobileTableGridCell = ({
  row,
  colKey,
  marked,
  editMode,
}: MobileTableGridCellProps) => {
  const type = row[colKey]?.type;
  const value = row[colKey]?.value;
  const formattedValue = row[colKey]?.formattedValue;
  const rowIndex = row[colKey]?.rowIndex;

  let parsedValue = value;

  switch (type) {
    case "date": {
      parsedValue = formattedValue;
      break;
    }
    case "time": {
      parsedValue = formattedValue;
      break;
    }
  }

  if (editMode) {
    return (
      <TableGridEditComponent
        colKey={colKey}
        type={type}
        colValue={parsedValue}
        rowIndex={rowIndex}
        handleSaveCellChanges={editMode.handleSaveCellChanges}
        label={editMode.fieldLabel}
      />
    );
  }

  return <TableGridCellComponent cellValue={parsedValue} type={type} marked={marked} />;
};
