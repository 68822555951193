import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer, Views, Event } from 'react-big-calendar'
import moment from 'moment'
import React, { useMemo, useEffect } from 'react'
import { Tooltip, Paper, Typography, Box } from '@mui/material';

const mLocalizer = momentLocalizer(moment)

interface EventsCalendarProps {
    events: Event[];
    onDateRangeChange: (start: Date, end: Date) => void;
}

interface CustomEvent extends Event {
    hoverData?: {
        client_order?: string;
        [key: string]: any;
    };
}

const ColoredDateCellWrapper = ({ children }: { children: React.ReactElement }) =>
    React.cloneElement(children, {
      style: {
        backgroundColor: 'lightblue',
      },
    })

const EventComponent = ({ event }: { event: CustomEvent }) => {
    const tooltipContent = event.hoverData ? (
        <Paper sx={{ p: 1.5, maxWidth: 300 }}>
            {Object.entries(event.hoverData).map(([key, value]) => (
                <Box component="div" key={key} sx={{ mb: 0.5 }}>
                    <Typography variant="caption" fontWeight="bold" sx={{ textTransform: 'capitalize' }}>
                        {key.replace(/_/g, ' ')}:
                    </Typography>{' '}
                    <Typography variant="body2" component="span">
                        {value}
                    </Typography>
                </Box>
            ))}
        </Paper>
    ) : null;

    return (
        <Tooltip
            title={tooltipContent || ''}
            arrow
            placement="top"
            followCursor
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 3,
                        borderRadius: 1,
                        p: 0,
                    }
                }
            }}
        >
            <div style={{ height: '100%', width: '100%' }}>
                <Typography variant="body2" noWrap>
                    {event.title}
                </Typography>
            </div>
        </Tooltip>
    );
};

const EventsCalendar = ({ events = [], onDateRangeChange }: EventsCalendarProps) => {
    const { components, defaultDate, views } = useMemo(
        () => ({
          components: {
            dateCellWrapper: ColoredDateCellWrapper,
            event: EventComponent,
          },
          defaultDate: new Date(),
          views: Object.keys(Views).map((k) => Views[k as keyof typeof Views]),
        }),
        []
    )

    useEffect(() => {
        const today = new Date();
        const start = new Date(today.getFullYear(), today.getMonth(), 1);
        const end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        onDateRangeChange(start, end);
    }, [onDateRangeChange]);

    const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
        if (Array.isArray(range)) {
            onDateRangeChange(range[0], range[range.length - 1]);
        } else {
            onDateRangeChange(range.start, range.end);
        }
    };
      
    return (
        <Box component="div" sx={{ 
            height: '90vh', 
            overflow: 'auto'
        }}>
            <Calendar
                components={components}
                defaultDate={defaultDate}
                events={events}
                localizer={mLocalizer}
                showMultiDayTimes
                step={60}
                views={views}
                startAccessor="start"
                endAccessor="end"
                onRangeChange={handleRangeChange}
                selectable
            />
        </Box>
    );
};

export default EventsCalendar;

