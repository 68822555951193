import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MUIDatePicker, DatePickerProps } from "@mui/x-date-pickers";
import enGB from "date-fns/locale/en-GB";

interface CustomDatePickerProps extends DatePickerProps<Date> {
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  helperText?: React.ReactNode;
  name?: string;
  error?: boolean | undefined;
}

const DatePicker: React.FC<CustomDatePickerProps> = ({
  className,
  onBlur,
  helperText,
  name,
  error,
  ...otherProps
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <div style={{ width: "98%" }}>
        <MUIDatePicker
          className={className}
          format="dd.MM.yyyy"
          slotProps={{
            textField: {
              variant: "standard",
              size: "small",
              onBlur,
              helperText: helperText,
              name: name,
              error: error,
            },
          }}
          {...otherProps}
        />
      </div>
    </LocalizationProvider>
  );
};

export default DatePicker;
