import React, { useEffect, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { useAuthedContext } from '../../context/AuthContext';
import { getQueryElpromScheduleOverviewData } from './API/apiElpromGetQueries';
import callApi, { Query } from '../../Api/callApi';
import ResponsiveTableGrid from '../../Components/SmallComponents/TableGrid/ResponsiveTableGrid';
import { TableGridData } from '../../Components/SmallComponents/TableGrid/tableGridUtils';
import { TableGridColumnSchema } from '../../Components/SmallComponents/TableGrid/constructTableGrid';
import ChatIcon from '@mui/icons-material/Chat';

const ProductionMonitoringPage: React.FC = () => {
  const { setAuthedUser } = useAuthedContext();
  const [tableData, setTableData] = useState<TableGridData>();

  const saveBy = "client_order";

  const addActionsToRows = (rows: any[]) => {
    return rows.map((row) => ({
      ...row,
      actions: (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton
            sx={{
              padding: 0,
            }}
            disabled={true}
          >
            <ChatIcon fontSize="small" />
          </IconButton>
        </Stack>
      )
    }));
  }

  const fetchData = async () => {
    try {
      const data = await callApi<TableGridData>({
        query: getQueryElpromScheduleOverviewData(),
        auth: { setAuthedUser },
    });

    const columnsWithActions: TableGridColumnSchema[] = [
      ...data.columns,
      { id: "actions", label: "Actions", type: "button" },
    ]

    const rowsWithActions = addActionsToRows(data.rows);

    const dataWithActions: TableGridData = {
      ...data,
      columns: columnsWithActions,
      rows: rowsWithActions,
    }  

    setTableData(dataWithActions);

    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveEditChanges = (editedValues: Record<string, any>) => {
    const saveUrl = tableData?.configuration?.globalEditUrl?.split("/v1/").pop();
    if(saveUrl) {
      const savedValues = Object.keys(editedValues).map((key) => {
        return {
          [saveBy]: key,
          ...editedValues[key],
        };
      });

      const saveQuery: Query = {
        endpoint: saveUrl,
        method: "PUT",
        variables: savedValues,
      };


      const saveData = async () => {
        try {
          await callApi<any>({
            query: saveQuery,
            auth: { setAuthedUser },
          });
        } catch (error) {
          console.error("Error saving data", error);
        }
      };
      
      saveData();
    }
  };

  return (
    tableData ? (
      <Box component="div">
        <ResponsiveTableGrid
          rows={tableData?.rows || []}
          colSchema={tableData?.columns || []}
          responsive="responsive"
          configuration={tableData?.configuration || {}}
          tableID="ProductionMonitoringPage_table"
          handleSaveEditChanges={handleSaveEditChanges}
          saveBy={saveBy}
        />
      </Box>
    ) : null
  );
};



export default ProductionMonitoringPage;
