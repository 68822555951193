import React, { useEffect, useState } from 'react';
import { Box, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useAuthedContext } from '../../context/AuthContext';
import callApi from '../../Api/callApi';
import ResponsiveTableGrid from '../../Components/SmallComponents/TableGrid/ResponsiveTableGrid';
import { getQueryElpromMaterialDeficitData, getQueryElpromFalseDeficitData as getQueryElpromProjectDeficitData } from './API/apiElpromGetQueries';
import { useLocation } from 'react-router-dom';
import cssSpacingStyles from '../../Global/Styles/spacing';
import { useLanguageContext } from '../../context/LanguageContext';
import LoadingBackdrop from '../../Components/MaterialUI/LoadingBackdrop';
import cssLayoutStyles from '../../Global/Styles/layout';
import { TableGridData } from '../../Components/SmallComponents/TableGrid/tableGridUtils';

const FalseDeficitPage: React.FC = () => {
  const { setAuthedUser } = useAuthedContext();
  const location = useLocation();
  const { t } = useLanguageContext();
  
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  
  const [projectDeficitsTableData, setProjectDeficitsTableData] = useState<TableGridData>();
  const [materialDeficitsTableData, setMaterialDeficitsTableData] = useState<TableGridData>();

  const [loading, setLoading] = useState(true);
  const [tabView, setTabView] = useState<number>(location.state?.tab || 0);

  const shouldHideTab = (_tab: string) => {
    //This can be used to hide tabs based on the user's permissions(or other conditions)
    //For now, all tabs are shown
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const projectDeficitsData = await callApi<TableGridData>({
          query: getQueryElpromProjectDeficitData(),
          auth: { setAuthedUser },
        });
        setProjectDeficitsTableData(projectDeficitsData);

        const materialDeficitsData = await callApi<TableGridData>({
          query: getQueryElpromMaterialDeficitData(),
          auth: { setAuthedUser },
        });
        setMaterialDeficitsTableData(materialDeficitsData);

      } catch (error) {
        console.error('Error fetching false deficits data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderTab = (tabView: number) => {
    switch (tabView) {
      case 0:
        return (
        <ResponsiveTableGrid
          rows={projectDeficitsTableData?.rows || []}
          colSchema={projectDeficitsTableData?.columns || []}
          responsive="responsive"
          configuration={projectDeficitsTableData?.configuration || {}}
          tableID="FalseDeficitPage_ProjectDeficitsTable"
          loading={loading}
        />
        )
      case 1:
        return (
        <ResponsiveTableGrid
          rows={materialDeficitsTableData?.rows || []}
          colSchema={materialDeficitsTableData?.columns || []}
          responsive="responsive"
          configuration={materialDeficitsTableData?.configuration || {}}
          tableID="FalseDeficitPage_MaterialDeficitsTable"
          loading={loading}
        />
        )
    }
  }

  return (
    <Stack 
      css={[styles.width100, styles.labelBreak]}
      spacing={2}
      alignItems={"center"} 
      justifyContent="center"
      >
      <Typography variant="h2" textAlign="center" mb={2}>
        {t("False Deficits")}
      </Typography>
      <Box component="div">
        <Tabs
          css={styles.contentBreak}
          sx={{ overflowX: "auto" }}
          value={tabView}
          onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
          aria-label="configure tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {!shouldHideTab("project_deficits") && <Tab label={t("Project Deficits")} value={0}/>}
          {!shouldHideTab("material_deficits") && <Tab label={t("Material Deficits")} value={1}/>}
        </Tabs>
      </Box>
      <Box component="div" css={[styles.width100]}>
      {loading ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={loading} />
        </Box>
      ) : renderTab(tabView)
      } 
    </Box>
    </Stack>
  );
};

export default FalseDeficitPage; 